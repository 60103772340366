<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 100px;">
                                采购单编号：
                            </td>
                            <td>
                                <el-input v-model="searchItem.buyNum" size="small" placeholder="请输入采购单编号"
                                ></el-input>
                            </td>
                            <td style="width: 80px">
                                采购仓库：
                            </td>
                            <td>
                                <el-select v-model="searchItem.warehouseType" style="width: 100%" size="small"
                                           placeholder="请选择采购仓库">
                                    <el-option
                                            v-for="item in depotTypes"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                采购时间：
                            </td>
                            <td>
                                <el-date-picker
                                        v-model="searchItem.startDate"
                                        type="date"
                                        size="small"
                                        placeholder="选择日期时间"
                                        style="width: 46.5%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                                至
                                <el-date-picker
                                        v-model="searchItem.endDate"
                                        type="date" placeholder="选择日期时间"
                                        size="small"
                                        style="width: 47%;"
                                        class="handle-input-date"
                                        value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if="hasAuthority('purchase_order_add')" @click="addPurchaseOrder">
                                    增加
                                </el-button>
                                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="50" label="排序" align="center"></el-table-column>
                            <el-table-column prop="buyNum" width="200" label="采购单编号" align="center"></el-table-column>
                            <el-table-column prop="applyNum" width="200" label="申购单编号" align="center"></el-table-column>
                            <el-table-column prop="createUserName" width="110" label="申请人"
                                             align="center"></el-table-column>
                            <el-table-column prop="applyType" width="150" label="采购类型" align="center">
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.applyType == 0">研发申购</span>
                                    <span v-else-if="scope.row.applyType ==1">生产申购</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="createTime" width="150" label="采购时间" :formatter="dateFormat"
                                             align="center"></el-table-column>
                            <el-table-column prop="warehouseType" width="150" label="采购仓库"
                                             align="center">
                                <template slot-scope='scope'>
                                    <span v-if="scope.row.warehouseType == 0">主物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==1">研发物料仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==2">外协仓库</span>
                                    <span v-else-if="scope.row.warehouseType ==3">售后仓库</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="auditState" width="120" label="采购进度"
                                             align="center">
                                <template slot-scope='scope'>
                                    <div v-if="scope.row.isInning == 1">
                                        <span style="color: blue">已入库</span>
                                    </div>
                                    <div v-else>
                                        <span v-if="scope.row.auditState ==10" style="color: green">已采购</span>
                                        <span v-else-if="scope.row.auditState ==-1" style="color: red">已驳回</span>
                                        <span v-else style="color: #20a0ff">采购中</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="remark" label="备注" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="id" width="150" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑" style="margin-right: 10%"
                                           v-if="hasAuthority('purchase_order_update') && scope.row.auditState == 0 ||
                                           hasAuthority('purchase_order_update') && scope.row.auditState == 10"
                                           @click="editPurchase(scope.row.id)"></i>
                                        <i class="iconfont iconxiangqing"
                                           title="详情"
                                           @click="showPurchase(scope.row.id)"></i>
                                        <i class="iconfont icontongguo" style="margin-left: 10%"
                                           v-if="hasAuthority('purchase_order_apply') && scope.row.auditState == 0"
                                           title="申请采购"
                                           @click="submitPurchase(scope.row.id)"></i>
                                        <i class="iconfont iconbohui" style="margin-left: 10%"
                                           v-if="hasAuthority('purchase_order_end') && scope.row.auditState == 10 && scope.row.isInning != 1"
                                           title="手动结束"
                                           @click="endPurchase(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-edit">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <div class="basic-info">
                    <el-form :model="purchaseForm" :rules="purchaseRules" ref="purchaseForm" label-width="100px"
                             class="demo-ruleForm">
                        <el-row>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item label="申购单编号" prop="applyId">
                                    <el-select
                                            size="small"
                                            style="width: 100%"
                                            :disabled="isDisable"
                                            v-model="purchaseForm.applyId"
                                            @change="pickingData"
                                            placeholder="请选择申购单编号"
                                    >
                                        <el-option
                                                v-for="item in purchaseList"
                                                :key="item.id"
                                                :label="item.applyNum"
                                                :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="10" style="width: 50%">
                                <el-form-item label="采购仓库" prop="name">
                                    <el-select
                                            size="small"
                                            style="width: 100%"
                                            disabled
                                            v-model="purchaseForm.warehouseType"
                                            placeholder=""
                                    >
                                        <el-option
                                                v-for="item in depotTypes"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-table
                                :data="materialList"
                                border
                                style="width: 100%;margin-bottom: 10px"
                                max-height="340"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column prop="materialCode" label="物料编码" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="count" label="申购数量" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="buyCounted" label="已采购数量" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column label="供应商" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div v-if="purchaseForm.auditState == 10">
                                        <span>{{scope.row.supplierName}}</span>
                                    </div>
                                    <div v-else>
                                        <el-select @focus="getChangeIndex(scope.$index)" size="small"
                                                   v-model="scope.row.supplierId" placeholder="请选择供应商"
                                                   filterable @change="changeSupplier">
                                            <el-option v-for="item in supplierList" :key="item.id"
                                                       :label="item.supplierName" :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="brand" label="品牌" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div v-if="purchaseForm.auditState == 10">
                                        <span>{{scope.row.brand}}</span>
                                    </div>
                                    <div v-else>
                                        <el-select size="small" v-model="scope.row.brand" placeholder="请选择品牌"
                                                   @focus="getChangeIndex"
                                                   filterable>
                                            <el-option v-for="item in scope.row.brandList" :key="item.value"
                                                       :label="item.label"
                                                       :value="item.value"></el-option>
                                        </el-select>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="buyPrice" label="采购数量" align="center"
                                             show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <div v-if="purchaseForm.auditState == 10">
                                        <span>{{scope.row.buyCount}}</span>
                                    </div>
                                    <el-input v-else size="small" type="number"
                                              v-model.number="scope.row.buyCount "
                                              @keydown.native="inputLimit"
                                              placeholder="请输入采购数量"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="buyPrice" label="采购单价(元)" align="center">
                                <template slot-scope="scope">
                                    <el-input-number size="small" :controls="false"
                                              v-model="scope.row.buyPrice"
                                              placeholder="请输入单价" :precision="2"></el-input-number>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-row>
                            <el-col :span="8" style="width: 50%;">

                                <el-form-item size="small" label="预计到货时间" prop="planArriveTime">
                                    <el-date-picker
                                            style="width: 100%"
                                            size="small"
                                            v-model="purchaseForm.planArriveTime"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item size="small" label="物流信息" prop="logistic">
                            <el-input size="small" type="textarea" rows="3" v-model="purchaseForm.logistic"></el-input>
                        </el-form-item>
                        <el-form-item size="small" label="备注" prop="remark">
                            <el-input size="small" type="textarea" rows="3" v-model="purchaseForm.remark"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('purchaseForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('purchaseForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="dialog-info">
            <el-dialog
                    :title="showTitle"
                    :visible.sync="infoDialogVisible"
                    width="70%"
                    :close-on-click-modal="false"
                    center>
                <table width="100%">
                    <tr>
                        <td width="100px" style="text-align: left">采购编号:</td>
                        <td style="text-align: left">{{purchaseDisplay.buyNum}}</td>
                        <td width="100px" style="text-align: left">申购单编号:</td>
                        <td style="text-align: left">{{purchaseDisplay.applyNum}}</td>
                        <td width="100px" style="text-align: left">采购仓库:</td>
                        <td style="text-align: left;">
                            {{purchaseDisplay.warehouseType == 0?"主物料仓库":
                            (purchaseDisplay.warehouseType == 1?"研发物料仓库" :
                            purchaseDisplay.warehouseType == 2?"外协仓库":'售后仓库')}}
                        </td>
                    </tr>
                    <tr>
                        <td colspan="6" style="padding-top: 10px">
                            <el-table
                                    :data="materialList"
                                    border
                                    style="width: 100%;margin-bottom: 10px"
                                    max-height="340"
                                    :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                    :cell-style="{padding:'0',height: lineHeight}">
                                <el-table-column prop="materialCode" label="物料编码" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="materialName" label="产品名称" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="model" label="型号" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="count" label="申购数量" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="supplierName" label="供应商" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="brand" label="品牌" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="buyCounted" label="已采购数量" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="buyCount" label="采购数量" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="buyPrice" label="采购单价(元)" align="center"
                                                 show-overflow-tooltip></el-table-column>
                                <el-table-column prop="inCounted" label="到货数量" align="center"
                                                 show-overflow-tooltip>
                                </el-table-column>
                            </el-table>
                        </td>
                    </tr>
                    <tr>
                        <td width="100px" style="text-align: left">预计到货时间：</td>
                        <td style="text-align: left;">
                            {{changeDateFormat(purchaseDisplay.planArriveTime)}}
                        </td>
                        <td width="100px" style="text-align: left">物流信息：</td>
                        <td style="text-align: left">
                            {{purchaseDisplay.logistic}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;padding-top: 10px">备注：</td>
                        <td colspan="5" style="text-align: left;padding-top: 10px">
                            {{purchaseDisplay.remark}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;padding-top: 10px">审批意见：</td>
                        <td colspan="5" style="text-align: left;padding-top: 10px">
                            {{purchaseDisplay.auditRemark}}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center;padding-top: 10px">结束理由：</td>
                        <td colspan="5" style="text-align: left;padding-top: 10px">
                            {{purchaseDisplay.reason}}
                        </td>
                    </tr>
                </table>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="infoDialogVisible = false">确 定</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="export">
            <el-dialog
                title="采购明细"
                :visible.sync="exportDialogVisible"
                width="80%"
                :close-on-click-modal="false"
                center
            >
                <div class="dialog-time">
                    <el-date-picker
                            v-model="startDate"
                            type="date"
                            size="small"
                            :clearable="false"
                            placeholder="选择开始时间"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    至
                    <el-date-picker
                            v-model="endDate"
                            type="date"
                            placeholder="选择结束时间"
                            size="small"
                            :clearable="false"
                            value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <el-button type="primary" size="mini" style="margin-left:15px" @click="dialogFilter">筛 选</el-button
                >
                </div>
                <el-table
                    :data="excelTableData"
                    max-height="400px"
                    size="small"
                    row-class-name="row"
                    cell-class-name="column"
                    :highlight-current-row="true"
                    fit
                >
                    <el-table-column
                    v-for="(item, index) in jsonFields"
                    :key="index"
                    :prop="item.prop"
                    :label="item.label"
                    show-overflow-tooltip
                    >
                    </el-table-column>
                </el-table>
                <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
                <download-excel
                        style="width: 80px;margin: 0px auto;margin-top: 10px;"
                        :data="json_data"
                        :fields="json_fields"
                        name="采购单"
                        >
                    <el-button
                        type="primary"
                        size="small"
                        @click="exportDialogVisible = false"
                        >导出数据
                    </el-button>
                </download-excel>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import dateFormat from '../../assets/js/dateFormat'
    export default {
        data() {
            return {
                excelTableData:[],
                startDate:'',
                endDate:'',
                jsonFields: [
                    { label: "采购订单号", prop: "buyNum" },
                    { label: "物料编码", prop: "materialCode" },
                    { label: "适用机型", prop: "useTypeName" },
                    { label: "部件", prop: "componentName" },
                    { label: "使用性质", prop: "useNatureName" },
                    { label: "产品名称", prop: "materialName" },
                    { label: "型号", prop: "model" },
                    { label: "单位", prop: "unitName" },
                    { label: "采购类型", prop: "applyType" },
                    { label: "采购日期", prop: "createTime" },
                    { label: "采购进度", prop: "auditState" },
                    { label: "采购数量", prop: "buyCount" },
                    { label: "单价(元)", prop: "buyPrice" },
                    { label: "金额合计(元)", prop: "buySum" },
                ],
                json_fields: {},
                json_data: [],
                exportDialogVisible: false,
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                purchaseList: [],
                materialList: [],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10,
                editDialogVisible: false,
                infoDialogVisible: false,
                lineHeight: '',
                showTitle: '',
                editTitle: '',
                type: 0,
                isLoad: false,
                searchItem: {
                    buyNum: '',
                    warehouseType: '',
                    startDate: '',
                    endDate: ''
                },
                depotTypes: [
                    {
                        value: 0,
                        label: '主物料仓库'
                    },
                    {
                        value: 1,
                        label: '研发物料仓库'
                    },
                    {
                        value: 2,
                        label: '外协仓库'
                    },
                    {
                        value: 3,
                        label: '售后仓库'
                    }
                ],
                purchaseForm: {
                    id: null,
                    applyId: '',
                    warehouseType: null,
                    buyDetailStr: '',
                    planArriveTime: '',
                    remark: '',
                    logistic: '',
                    auditState: 0,
                },
                purchaseDisplay: {
                    buyNum: '',
                    warehouseType: null,
                    applyNum: '',
                    planArriveTime: '',
                    remark: '',
                    auditRemark: '',
                    logistic: '',
                    reason: '',
                },
                singlePrice: '',
                purchaseRules: {
                    applyId: [{required: true, message: '请选择申购单据不能为空', trigger: 'blur'}],
                    planArriveTime: [{required: true, message: '请选择预计到货时间不能为空', trigger: 'blur'}],
                    logistic: [{required: true, message: '请选择物流信息不能为空', trigger: 'blur'}],
                },
                brandList: [],
                supplierList: [],
                changeFlag: 0,
                tableIndex: 0,
                isDisable: false,
            };
        },
        methods: {
            //导出excel
            exportExcel() {
                this.exportDialogVisible = true;
                var day1 = new Date();
                day1.setDate(day1.getDate() + 1);
                this.startDate = dateFormat("YYYY-mm-DD", new Date())
                this.endDate = dateFormat("YYYY-mm-dd ", day1)
                this.dialogFilter()
            },
            //导出excel的筛选
            dialogFilter(){
                this.$api.applyDetailPrint({startDate:this.startDate,endDate:this.endDate}).then(res=>{
                    if(res.code == 200){
                        res.data.forEach(item => {
                            item.buyPrice = (item.buyPrice/100).toFixed(2)
                            item.buySum = (item.buySum/100).toFixed(2)
                            item.applyType = item.applyType==0?'研发采购':'生产采购'
                            if(item.isInning == 1){
                                item.auditState = '已入库'
                            }else{
                                if(item.auditState == 10){
                                    item.auditState = '已采购'
                                }else if(item.auditState == -1){
                                    item.auditState = '已驳回'
                                }else{
                                    item.auditState = '采购中'
                                }
                            }
                        });
                        this.excelTableData = res.data
                        this.json_data = res.data
                    }
                })
            },
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.getPurchaseOrder(this.searchItem).then(res => {
                    if (res.code == 200) {
                        let tempData = res.data.content.filter(item => item.auditState !== -1)
                        this.tableData = tempData;
                        // this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
            },
            reset() {
                this.searchItem = {
                    buyNum: '',
                    warehouseType: '',
                    startDate: '',
                    endDate: ''
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addPurchaseOrder() {
                this.type = 0
                this.editDialogVisible = true;
                this.isLoad = false;
                this.isDisable = false;
                this.editTitle = '新增采购信息'
                this.materialList = []
                this.purchaseForm = {
                    id: null,
                    applyId: '',
                    warehouseType: null,
                    buyDetailStr: '',
                    planArriveTime: '',
                    remark: '',
                    logistic: '',
                    auditState: 0,
                };
                this.$api.getPurchaseList().then(res => {
                    if (res.code == 200) {
                        let tempData = res.data.applyList.filter(item => item.auditState !== -1)
                        this.purchaseList = tempData
                        // this.purchaseList = res.data.applyList
                        this.supplierList = res.data.supplierList;
                    }
                })
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var materialListStr = '';
                for (var item of this.materialList) {
                    if (item.buyCount == '' || item.buyCount == undefined
                        || item.buyCount == null || item.buyCount == 0) {
                        continue;
                    } else {
                        if (item.buyPrice == '' || item.buyPrice == undefined
                            || item.buyPrice == null || item.buyPrice == 0) {
                            this.$message.error('采购物料的采购单价不能为空或0');
                            this.isLoad = false;
                            return;
                        }
                        if (item.supplierId == '' || item.supplierId == undefined
                            || item.supplierId == null) {
                            this.$message.error('采购物料的供应商不能为空');
                            this.isLoad = false;
                            return;
                        }

                        if (materialListStr == '') {
                            materialListStr = item.id + ',' + item.buyCount + ',' + (item.buyPrice * 100).toFixed() + ','
                                + item.supplierId + ',' + item.brand
                        } else {
                            materialListStr = materialListStr + '|' + item.id + ',' + item.buyCount + ',' + (item.buyPrice * 100).toFixed() + ','
                                + item.supplierId + ',' + item.brand
                        }
                    }
                }
                if (materialListStr == '') {
                    this.$message.error('采购订单的物料数据必须有一条不为空的数据');
                    this.isLoad = false;
                    return;
                }
                this.purchaseForm.buyDetailStr = materialListStr
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        var materialData = JSON.parse(JSON.stringify(this.purchaseForm));
                        materialData.planArriveTime = materialData.planArriveTime + ' 00:00:00'
                        if (this.type == 0) {
                            this.$api.addPurchaseOrder(materialData).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updatePurchaseOrder(materialData).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                        return false;
                    }
                });
            },
            dateFormat(row, column) {
                let data = row[column.property]
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            changeDateFormat(data) {
                let dt = new Date(data)
                var year = dt.getFullYear();
                var month = (dt.getMonth() + 1) < 10 ? 0 + '' + (dt.getMonth() + 1) : ((dt.getMonth() + 1))
                var date = dt.getDate() < 10 ? 0 + '' + dt.getDate() : (dt.getDate())
                return year + '-' + month + '-' + date
            },
            editPurchase(id) {
                this.editDialogVisible = true;
                this.isDisable = true;
                this.isLoad = false;
                this.type = 1
                this.$api.getPurchaseList().then(res => {
                    if (res.code == 200) {
                        let tempData = res.data.applyList.filter(item => item.auditState !== -1)
                        this.purchaseList = tempData
                        this.supplierList = res.data.supplierList;
                        // this.purchaseList = res.data.applyList
                        // this.supplierList = res.data.supplierList
                        this.$api.getPurchaseOrderById({id: id}).then(res => {
                            if(res.code != 200){
                                this.$message.error(res.message);
                                return
                            }
                            this.editTitle = '编辑《' + res.data.buyNum + '》采购信息'
                            this.purchaseForm = {
                                id: res.data.id,
                                applyId: res.data.applyId,
                                warehouseType: res.data.warehouseType,
                                planArriveTime: !res.data.planArriveTime?'':res.data.planArriveTime.split(" ")[0],
                                remark: res.data.remark,
                                logistic: res.data.logistic,
                                auditState: res.data.auditState,
                            }
                            if(res.data.materialList.length <= 0){
                                this.$api.getApplyMaterialListById({applyId: res.data.applyId}).then(res => {
                                    if (res.code == 200) {
                                        for (var item of res.data) {
                                            this.brandList = [];
                                            item.buyPrice = item.buyPrice == 0 ? 0 : (item.buyPrice / 100).toFixed(2)
                                            for (var item1 of this.supplierList) {
                                                if (item1.id == item.supplierId){
                                                    var newArr = item1.supplierBrand.split('|')
                                                    for (var item2 of newArr) {
                                                        var itemObj = {};
                                                        itemObj.value = item2;
                                                        itemObj.label = item2;
                                                        this.brandList.push(itemObj)
                                                    }
                                                }
                                            }
                                            item.brandList = this.brandList;
                                        }
                                        this.materialList = res.data;
                                    }
                                })
                                return;
                            }else{
                                for (var item of res.data.materialList) {
                                    this.brandList = [];
                                    item.buyPrice = item.buyPrice == 0 ? 0 : (item.buyPrice / 100).toFixed(2)
                                    for (var item1 of this.supplierList) {
                                        if (item1.id == item.supplierId){
                                            var newArr = item1.supplierBrand.split('|')
                                            for (var item2 of newArr) {
                                                var itemObj = {};
                                                itemObj.value = item2;
                                                itemObj.label = item2;
                                                this.brandList.push(itemObj)
                                            }
                                        }
                                    }
                                    item.brandList = this.brandList;
                                }
                                this.materialList = res.data.materialList;
                            }
                        })
                    }
                })
            },
            showPurchase(id) {
                this.infoDialogVisible = true;
                this.$api.getPurchaseOrderById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.showTitle = '查看《' + res.data.buyNum + '》采购信息详情'
                        this.purchaseDisplay = {
                            buyNum: res.data.buyNum,
                            applyNum: res.data.applyNum,
                            warehouseType: res.data.warehouseType,
                            planArriveTime: res.data.planArriveTime,
                            auditRemark: res.data.auditRemark,
                            remark: res.data.remark,
                            logistic: res.data.logistic,
                            reason: res.data.reason,
                        }
                        for (var item of res.data.materialList) {
                            item.buyPrice = item.buyPrice == 0 ? 0 : (item.buyPrice / 100).toFixed(2)
                        }
                        this.materialList = res.data.materialList;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            submitPurchase(id) {
                this.$confirm('你确定要提交该采购单信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.submitPurchaseOrder({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                })
            },
            endPurchase(id) {
                this.$prompt('请输入手动结束的理由', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    center: true
                }).then(({value}) => {
                    this.$api.endPurchaseOrder({id: id, reason: value}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消手动结束'
                    });
                });
            },

            getChangeIndex(index) {
                this.tableIndex = index;
            },
            changeSupplier(val) {
                this.brandList = []
                for (var argument of this.supplierList) {
                    if (argument.id == val) {
                        var newArr = argument.supplierBrand.split('|');
                        for (var item of newArr) {
                            var itemObj = {};
                            itemObj.value = item;
                            itemObj.label = item;
                            this.brandList.push(itemObj)
                        }
                    }
                }
                for (var i = 0; i < this.materialList.length; i++) {
                    if (this.tableIndex == i) {
                        this.materialList[i].brand = '';
                        this.materialList[i].buyPrice = '';
                        this.materialList[i].brandList = this.brandList;
                    }
                }
            },
            pickingData(val) {
                for (var item of this.purchaseList) {
                    if (val == item.id) {
                        this.purchaseForm.warehouseType = item.warehouseType
                    }
                }
                this.$api.getApplyMaterialListById({applyId: val}).then(res => {
                    if (res.code == 200) {
                        this.materialList = res.data;
                    }
                })
            },
            inputLimit (e) {
                let key = e.key
                // 不允许输入'e'和'.'、'+'、'-'、'Shift'
                if (key === 'e' || key === '.' || key === '+' || key === '-' || key === 'Shift') {
                    e.returnValue = false
                    return false
                }
                return true
            }

        },
        created() {
            this.jsonFields.map((item, i) => {
                this.json_fields[item.label] = item.prop;
            });
            this.pageChange(1)
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .iconbohui {
        color: red;
    }

    .icontongguo {
        color: green;
    }

    .iconbianji {
        color: #20a0ff;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .opertion {
        display: flex;
        justify-content: center;
    }

    td {
        text-align: right;
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }

    .el-col {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>